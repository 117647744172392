import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from 'gatsby';
import { stringify, parse } from 'querystring';
import { useLocation } from '@reach/router';
import { setQueryString } from '@a1-ict/fsbo/searchSlice';

var timeoutId = null;

const useFilters = () => {
  const filters = useSelector((state) => state.search.queryParams);
  const location = useLocation();
  const dispatch = useDispatch();
  const query = parse(location.search.substring(1));

  const locationState = location.state;

  const serialize = (filters) => {
    const filterQuery = stringify({ ...filters });
    return filterQuery;
  };

  const handleSearch = (config) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      navigate(
        (config.url || '') + '?' + serialize(config.params ? { ...config.params } : { ...filters }),
        { replace: true }
      );
    }, config.delay || 0);
  };

  const setParameters = (params, triggerSearch) => {
    dispatch(setQueryString({ ...params }));
    if (triggerSearch) handleSearch({ params: params });
  };

  const prettify = (query) => {
    for (var prop in query) {
      if (typeof query[prop] === 'object') prettify(query[prop]);
      if (String(parseInt(query[prop])) === query[prop]) {
        query[prop] = parseInt(query[prop]);
      }
    }
    return query;
  };

  return {
    setSearchParams: (params, triggerSearch = false) => setParameters(params, triggerSearch),
    getSearchParams: () => filters,
    getQueryString: () => serialize({ ...filters }),
    search: (config = {}) => handleSearch(config),
    parse: () => prettify(query),
    getState: () => locationState,
  };
};

export default useFilters;
