import React, { useState, useEffect } from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import useFilters from '../useFilters';
import { useDispatch, useSelector } from 'react-redux';

import { useLocation } from '@a1-ict/gatsby/utils/locationContext';
import { FaHistory } from '@react-icons/all-files/fa/FaHistory';
import { IoIosPin } from '@react-icons/all-files/io/IoIosPin';

import { fetchAddressById, fetchAddresses, getLastSearches } from '@a1-ict/fsbo/searchSlice';
import { getLastSearchesNames } from '../Common/getLastSearchesNames';

import {
  selectAllFilters,
  selectLastSearches,
  selectSearchAddresses,
} from '@a1-ict/fsbo/searchSlice/selectors';
import { selectPropertyCategories } from '@a1-ict/fsbo/propertySlice/selectors';

import { debounce } from 'lodash'

import useDeepEffect from '@a1-ict/core/utils/useDeepEffect';

const getLastSearchesOptionsArray = (data) => {
  let i = 0;

  const reducedLabels = data.map((value) => {
    const reduced = value.values.reduce((prev, val) => {
      return prev ? `${prev}; ${val.text}` : ` ${val.text}`;
    }, '');

    i++;
    return {
      id: value.params.id || i,
      text: reduced,
      icon: <FaHistory size={18} className="mr-1 text-dark" />,
      params: value.params,
    };
  });
  return reducedLabels.filter((value) => {
    return !!value.text;
  });
};

const getAddressOption = (item) => {
  const params = {};
  const option = {};

  if (item.neighborhood_id) {
    params.NeighborhoodId = item.neighborhood_id;
    if (item.neighborhood_center_longitude) {
      params.CentreLatitude = item.neighborhood_center_latitude
      params.CentreLongitude = item.neighborhood_center_longitude
      params.Radius = item.radius ? (item.radius === 0 ? 1.5 : item.radius + 1) : 1.5;
    }
    option.text = `${item.neighborhood_name} (${item.city_name})`;
    option.id = `NeighborhoodId=${item.neighborhood_id}`;
  } else if (item.city_id) {
    params.CityId = item.city_id;
    if (item.city_center_latitude) {
      params.CentreLatitude = item.city_center_latitude;
      params.CentreLongitude = item.city_center_longitude;
      params.Radius = item.radius || 10;
    }
    option.text = `${item.city_name} (обл. ${item.district_name})`;
    option.id = `CityId=${item.city_id}`;
  } else if (item.district_id) {
    params.DistrictId = item.district_id;
    option.text = `Обл. ${item.district_name}`;
    option.id = `DistrictId=${item.district_id}`;
  }
  return {
    params: params,
    text: option.text,
    id: option.id,
  };
};

const IconOption = (props) => {
  return (
    <components.Option {...props} className="option-icon">
      {props.data.icon}
      {props.data.text}
    </components.Option>
  );
};

const SearchFormLocation = ({
  size = 'md',
  onAfterChange = () => { },
  clearSelectedFilters = true,
  className,
  placeholder = 'Въведете град и квартал',
  setSearchByBounds,
  setMapSettings,
  noOptionsMessageProp = 'Моля започнете да пишете',
}) => {
  const filters = useFilters();
  const filterData = filters.getSearchParams();
  const currentLocation = useLocation();

  const lastSearches = useSelector(selectLastSearches);
  const availableFilters = useSelector(selectAllFilters);
  const categories = useSelector(selectPropertyCategories);
  const [address, setAddress] = useState([]);

  const selectedFilters = filters.parse();

  const [lastSearchesValues, setLastSearchesValues] = useState([]);
  const [searchTerm, setSearchTerm] = useState('')
  const [selectValue, setSelectValue] = useState();
  const [options, setOptions] = useState();

  const dispatch = useDispatch();

  const getAddressQueryString = async (selectedFilters) => {
    let queryString = '';
    let address = {};

    if (selectedFilters.NeighborhoodId)
      queryString = `NeighborhoodId=${selectedFilters.NeighborhoodId}`;
    else if (selectedFilters.CityId) queryString = `CityId=${selectedFilters.CityId}`;
    else if (selectedFilters.DistrictId) queryString = `DistrictId=${selectedFilters.DistrictId}`;

    if (queryString) {
      address = await dispatch(fetchAddressById({ queryString }));
    }
    return { queryString, address };
  };

  useEffect(async () => {
    //dispatch(getLastSearches({ settings }));
    const { queryString, address } = await getAddressQueryString(selectedFilters);
    if (address.payload && address.payload.data) setAddress([address.payload.data]);
    setSelectValue({ id: queryString });
  }, []);

  // useEffect(async () => {
  //   if (lastSearches) {
  //     const data = await Promise.all(
  //       lastSearches.map(async (search) => {
  //         const { address } = await getAddressQueryString(search);

  //         return getLastSearchesNames(
  //           search,
  //           availableFilters,
  //           categories,
  //           address.payload && address.payload.data && getAddressOption(address.payload.data)
  //         );
  //       })
  //     );

  //     const optionArray = getLastSearchesOptionsArray(data);
  //     setLastSearchesValues(optionArray);
  //   }
  // }, [lastSearches, availableFilters, categories]);

  useEffect(() => {
    if (selectValue?.id) onAfterChange();
  }, [selectValue]);

  useDeepEffect(() => {
    setOptions(getOptions(lastSearchesValues));
  }, [lastSearchesValues, address, currentLocation]);

  const fetchAddressData = async (searchString) => {

    const response = await dispatch(fetchAddresses({ searchString }));

    return response.payload.data.map((item) => {
      return getAddressOption(item);
    });
  };

  const getOptions = (lastSearchesValues) => {
    const options = [];

    if (currentLocation && currentLocation.lat && currentLocation.lng) {
      options.push({
        params: {
          CentreLatitude: currentLocation.lat,
          CentreLongitude: currentLocation.lng,
          Radius: 3,
        },
        text: `Текущо местоположение`,
        icon: <IoIosPin size={22} className="mr-1 text-dark" />,
        id: -1,
      });
    }
    options.push(...lastSearchesValues);

    address && address.length > 0 && address.map((item) => options.push(getAddressOption(item)));
    return options;
  };


  const debouncedFetch = debounce((searchTerm, callback) => {
    const result = fetchAddressData(searchTerm)
    result.then((data => callback(data))).catch((_) => callback([]))
  }, 600);


  return (
    <AsyncSelect
      defaultOptions={options}
      loadOptions={debouncedFetch}
      loadingMessage={() => 'Зареждане...'}
      onChange={(e) => {
        // filters.setSearchParams({});
        setSelectValue(e);
        if (clearSelectedFilters) filters.setSearchParams({ ...e.params });
        else {
          // const {
          //   NeighborhoodId,
          //   CityId,
          //   DistrictId,
          //   CentreLatitude,
          //   CentreLongitude,
          //   Radius,
          //   ...restFilters
          // } = filterData;
          // let filterDataCopy = { ...restFilters };
          // setSearchByBounds(false);
          setMapSettings(e.params);
          // delete filterDataCopy.UpperRightLongitude;
          // delete filterDataCopy.UpperRightLatitude;
          // delete filterDataCopy.LowerLeftLongitude;
          // delete filterDataCopy.LowerLeftLatitude;
          // filters.setSearchParams({ ...filterDataCopy, ...e.params, CurrentPage: 1 });
        }
      }}
      value={options && selectValue ? options.find((el) => el.id === selectValue.id) : ''}
      components={{
        Option: (inputProps) => <IconOption {...inputProps} />,
      }}
      getOptionLabel={(option) => option.text}
      getOptionValue={(option) => option.id}
      className={` ${size === 'sm' && 'search-select bg-light rounded-lg text-primary'
        } ${className}`}
      placeholder={placeholder}
      noOptionsMessage={({ inputValue }) =>
        !inputValue ? noOptionsMessageProp : 'Няма намерени резултати'
      }
    />
  );
};

export default SearchFormLocation;
