import React from 'react';
import { Col, Card, CardBody, CardTitle } from 'reactstrap';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { BiMap } from '@react-icons/all-files/bi/BiMap';
import { FaCamera } from '@react-icons/all-files/fa/FaCamera';
import { FaVideo } from '@react-icons/all-files/fa/FaVideo';

import { useBreakpoint } from '@a1-ict/gatsby/utils/breakpoint';

import goldBadge from '../../images/gold.png';
import silverBadge from '../../images/silver.png';

import Icon from '../Common/Icons';
import FavoriteButton from '../Common/Buttons/FavoriteButton';
import {
  getConstructionTypeIcon,
  getFeatureIcon,
  mainFeatures,
  thousandsSeparator,
} from './helpers';

function PropertyCard({ variant = 'vertical', property, isSearchResult = false }) {
  const gatsbyImg =
    property.gatsbyImages &&
    property.gatsbyImages[0] &&
    property.gatsbyImages[0].localFile.childImageSharp.gatsbyImageData;

  const breakpoints = useBreakpoint();

  const {
    is_building,
    bedrooms,
    bathrooms,
    size,
    yard_size,
    construction_type,
    is_price_negotiated,
    building_floors,
    floor,
  } = property;

  const constructionTypeIcon = getConstructionTypeIcon(construction_type);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  return (
    <Col
      xl={variant === 'horizontal' ? 12 : 4}
      md={variant === 'horizontal' ? 12 : 6}
      sm={12}
      className=" m-md-0 px-md-4 p-sm-3 py-xs-4 px-sm-5 px-xs-2"
    >
      <Card
        className={`property_card h-100 ${
          variant === 'horizontal' && !breakpoints.lg
            ? 'd-flex flex-row'
            : variant === 'horizontal' && breakpoints.sm && !breakpoints.xs
            ? 'd-flex flex-row'
            : ''
        }`}
      >
        <div
          className={`property_card_header ${
            variant === 'horizontal' && !breakpoints.lg
              ? 'horizontal'
              : variant === 'horizontal' && breakpoints.sm && !breakpoints.xs
              ? 'horizontal'
              : ''
          }`}
        >
          <Link to={`/property/${property.id}`} className="links">
            <div className="property_card_header_img_container m-2 overflow-hidden">
              {gatsbyImg ? (
                <GatsbyImage
                  image={gatsbyImg}
                  alt="Property for sale or rent"
                  imgClassName="property_card_image"
                  objectFit="cover"
                  style={{ height: '100%' }}
                />
              ) : (
                <div
                  className="property_card_header_img_container property_card_image"
                  style={
                    property.images && property.images[0]
                      ? {
                          backgroundImage: `url(${process.env.GATSBY_BACKEND}${property.images[0].url})`,
                        }
                      : {}
                  }
                ></div>
              )}
            </div>
          </Link>
          {property.is_golden && (
            <div className="property_card_badge">
              <img alt="badge" src={goldBadge} width="60px" height="70px"></img>
            </div>
          )}
          {property.is_silver && (
            <div className="property_card_badge">
              <img alt="badge" src={silverBadge} width="60px" height="70px"></img>
            </div>
          )}
        </div>
        <div className="d-flex flex-column flex-grow-1">
          <CardBody className="px-3 pb-1 pt-2">
            <div className="d-flex justify-content-between align-items-start mb-1 flex-wrap">
              <div className="property_card__media d-flex align-items-center ">
                <div className="property_card_icon mr-1">
                  <FaCamera></FaCamera>
                </div>{' '}
                <span className="mr-2">{property.images && property.images.length}</span>
                {property.video && (
                  <>
                    {' '}
                    <div className="property_card_icon mr-1">
                      <FaVideo fontSize="18"></FaVideo>
                    </div>{' '}
                    <span>1</span>{' '}
                  </>
                )}
              </div>
              <div className="d-flex flex-column align-items-end">
                {is_price_negotiated && (
                  <div className="property_card_price mb-1">
                    <span className="property_card_price_label">По договаряне</span>
                  </div>
                )}
                {property.price && (
                  <div className="property_card_price mb-1">
                    <span className="property_card_price_label">
                      {numberWithCommas(property.price)}
                    </span>
                    <span> EUR</span>
                  </div>
                )}
                {property.square_price && (
                  <div className="property_card__avg_price">
                    {thousandsSeparator(property.square_price?.toFixed(2))} EUR/кв.м.
                  </div>
                )}
              </div>
            </div>
            <CardTitle tag="h5" className="property_card_title_container mb-1">
              <Link
                to={`/property/${property.id}`}
                className="property_card_title links"
                state={{ resetFilter: true }}
              >
                {property.title}
              </Link>
              <FavoriteButton propertyId={property.id} isFavorite={property.is_favorite} />
            </CardTitle>
            <div className="property_card_address mt-0 mb-2 d-flex align-items-center">
              {(property.street || property.neighborhood || property.city) && (
                <div className="property_card_icon">
                  <BiMap></BiMap>
                </div>
              )}

              {property.street && <span className="property_card_street">{property.street},</span>}
              {property.neighborhood && (
                <span className="property_card_area ml-1">
                  <Link
                    to={`/buy-view?NeighborhoodId=${property.neighborhood_id}`}
                    className="links"
                    state={{ resetFilter: true }}
                  >
                    {property.neighborhood},
                  </Link>
                </span>
              )}
              <span className="property_card_city ml-1">
                <Link
                  to={`/buy-view?CityId=${property.city_id}`}
                  className="links"
                  state={{ resetFilter: true }}
                >
                  {property.city}
                </Link>
              </span>
            </div>
            <div
              className={`property_card_description ${
                isSearchResult && 'search_results_description'
              } mb-2`}
            >
              {property.description}
            </div>
            <ul className="details_section_details property_card_icons align-items-center d-flex my-0">
              {floor && (
                <li className="mr-2 mb-1">
                  <Icon icon="stairs" size="sm" />
                  <span className="ml-1">
                    {floor} от {building_floors}
                  </span>
                </li>
              )}
              {((is_building && building_floors) || (building_floors && !floor)) && (
                <li className="mr-2 mb-1">
                  <Icon icon="stairs" size="sm" />
                  <span className="ml-1">Етажи: {building_floors}</span>
                </li>
              )}
              {bedrooms && (
                <li className="mr-2 mb-1">
                  <Icon icon="rooms" size="sm" />
                  <span className="ml-1">
                    {bedrooms} {bedrooms == 1 ? 'стая' : 'стаи'}
                  </span>
                </li>
              )}
              {bathrooms && (
                <li className="mr-2 mb-1 ">
                  <Icon icon="bathrooms" size="sm" />
                  <span className="ml-1">
                    {bathrooms} {bathrooms == 1 ? 'баня' : 'бани'}
                  </span>
                </li>
              )}
              {size && (
                <li className="mr-2  mb-1">
                  <Icon icon="size" size="sm" />
                  <span className="ml-1"> {size} кв.м.</span>
                </li>
              )}
              {yard_size && (
                <li className="mr-2  mb-1">
                  <Icon icon="size" size="sm" />
                  <span className="ml-1"> {yard_size} кв.м. двор</span>
                </li>
              )}

              {construction_type && (
                <li className="mr-2  mb-1">
                  <Icon icon={constructionTypeIcon} size="sm" />
                  <span className="ml-1">{construction_type}</span>
                </li>
              )}

              {property.features
                .filter((feature) => mainFeatures.includes(feature.name))
                .map((feature) => (
                  <li key={feature} className="mr-2  mb-1">
                    <Icon icon={getFeatureIcon(feature.text)} size="sm" />
                    <span className="ml-1">{feature.text}</span>
                  </li>
                ))}
            </ul>
            {property.farmland_type && (
              <div className="mt-2 mr-2  mb-1">Тип земя: {property.farmland_type}</div>
            )}
          </CardBody>
        </div>
      </Card>
    </Col>
  );
}

export default PropertyCard;
