export const selectFilterByResource = (
  state,
  resource,
  filterOptionsCriteria = null
) => {
  const found = state.search.filters.find((filter) => filter.name === resource);

  if (filterOptionsCriteria) {
    const enabled = state.search.filtersSpecific[filterOptionsCriteria];
    return {
      ...found,
      items: found.items.filter((x) => enabled.includes(x.id)),
    };
  }

  return found;
};

export const selectQueryDataByResource = (state, resource) => {
  let selectedOptions = [];

  let found = state.search.queryParams[resource];

  if (found && typeof found !== "object") {
    selectedOptions.push(found);
  } else if (found) selectedOptions = [...found];

  return selectedOptions;
};

export const selectAllFilters = (state) => state.search.filters;

export const selectLastSearches = (state) => state.search.lastSearches;

export const selectEnabledCategories = (state) =>
  state.search.filtersSpecific.categories;

export const selectEnabledFilters = (state) =>
  state.search.filtersSpecific.filters;

export const selectAdditionalPropertiesFilters = (state) => {
  const additional = state.search.filtersSpecific.additional;

  const res = {};
  for (const key in additional) {
    res[key] = {};
    const parsed = parseInt(additional[key], 10);
    res[key] = isNaN(parsed) ? additional[key] : parsed;
  }

  return res;
};

export const selectSearchAddresses = (state) => state.search.addresses;
