import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { usePrivateFunction } from '../../Security/usePrivateFunction';

import { AiOutlineHeart } from '@react-icons/all-files/ai/AiOutlineHeart';
import { AiFillHeart } from '@react-icons/all-files/ai/AiFillHeart';
import { toast } from 'react-toastify';

import { addFavoriteProperty, deleteFavoriteProperty } from '@a1-ict/fsbo/userSlice/requests';
import { settings } from '../../../settings';

const FavoriteButton = ({ propertyId, isFavorite = false, ...rest }) => {
  const [isAdded, setIsAdded] = useState(isFavorite);
  const dispatch = useDispatch();
  const { secure } = usePrivateFunction();

  useEffect(() => {
    setIsAdded(isFavorite);
  }, [isFavorite]);

  const save = () => {
    secure(async () => {
      let response;
      if (isAdded) {
        response = await dispatch(
          deleteFavoriteProperty({ dispatch, settings, property_id: propertyId })
        );
      } else {
        response = await dispatch(
          addFavoriteProperty({ dispatch, settings, property_id: propertyId })
        );
      }
      if (response.payload && response.payload.ok) setIsAdded(!isAdded);
      else
        toast.error('Проблем със запазването на данните', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
    });
  };

  return (
    <>
      <div className="property_card_favorite" onClick={save}>
        {isAdded ? <AiFillHeart {...rest} /> : <AiOutlineHeart {...rest} />}
      </div>
    </>
  );
};

export default FavoriteButton;
